.cookie-policy {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #2d2d2d;
  color: #ffffff;
  padding: 20px;
  box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cookie-policy-content {
  flex: 1;
  margin-right: 20px; /* Space between content and buttons */
}

.cookie-policy-content p {
  margin: 0;
  font-size: 14px;
}

.cookie-buttons {
  display: flex;
  gap: 10px;
}

.cookie-button {
  font-size: 14px;
  border-radius: 4px;
  padding: 8px 16px; /* Adjust padding for smaller buttons */
}

.accept-button {
  background-color: #4CAF50;
  border-color: #4CAF50;
  color: #fff;
}

.reject-button {
  background-color: #f44336;
  border-color: #f44336;
  color: #fff;
}

/* Media query for mobile screens */
@media (max-width: 600px) {
  .cookie-policy {
    flex-direction: column;
    text-align: center;
    padding: 10px;
  }

  .cookie-policy-content {
    margin-right: 0;
    margin-bottom: 10px;
  }

  .cookie-buttons {
    flex-direction: row;
    gap: 5px; /* Reduce space between buttons */
  }

  .cookie-button {
    font-size: 12px; /* Smaller text size */
    padding: 6px 12px; /* Smaller padding */
  }

  .cookie-policy-content p {
    font-size: 12px;
  }
}
